import { default as NextHead } from 'next/head';
import { useRouter } from 'next/router';
import { useSSRContext } from '../../hooks/useSSRContext';
import { LANGUAGE_EN } from '../../variables';

const Head = ({
    children,
    title = '',
    description = '',
    alternateRefs = {
        [LANGUAGE_EN]: '/'
    }
}) => {
    const router = useRouter();
    const { hostName } = useSSRContext();
    const [path] = router.asPath.split('?');
    const currentUrl = `${hostName}/${router.locale}${path}`;

    return (
        <NextHead>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            {description && <meta name="description" content={description} />}
            {description && <meta property="og:description" content={description} />}
            <link rel="canonical" href={currentUrl} />
            <title>{title}</title>
            {Object.keys(alternateRefs).map((lang) => (
                <link key={lang} rel="alternate" hrefLang={lang} href={`${hostName}/${lang}${alternateRefs[lang]}`} />
            ))}
            {children}
        </NextHead>
    );
};

export default Head;
